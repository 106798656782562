// extracted by mini-css-extract-plugin
export var alertRed = "ComparisonTable-module--alert-red--ba2c0";
export var blackText = "ComparisonTable-module--black-text--06f9f";
export var comparisonTable = "ComparisonTable-module--comparisonTable--94f7a";
export var copper = "ComparisonTable-module--copper--e22d1";
export var darkGrey = "ComparisonTable-module--dark-grey--c318e";
export var deadSalmon = "ComparisonTable-module--dead-salmon--070fb";
export var greyPlaceholder = "ComparisonTable-module--grey-placeholder--d27ab";
export var greyText = "ComparisonTable-module--grey-text--e239d";
export var header = "ComparisonTable-module--header--39511";
export var image = "ComparisonTable-module--image--a6144";
export var label = "ComparisonTable-module--label--a94f7";
export var lightGrey = "ComparisonTable-module--light-grey--6ca86";
export var logo = "ComparisonTable-module--logo--ded9e";
export var ming = "ComparisonTable-module--ming--29dd8";
export var mingHover2 = "ComparisonTable-module--ming-hover-2--9563c";
export var teal = "ComparisonTable-module--teal--9869c";
export var white = "ComparisonTable-module--white--faf6f";
export var womenRed = "ComparisonTable-module--women-red--46442";
export var womenSharp = "ComparisonTable-module--women-sharp--bed00";