import React, { Fragment } from "react"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Img from "../Img"
import * as style from "./ComparisonTable.module.scss"
import tick from "../../images/comparison/tick.svg"
import cross from "../../images/comparison/cross.svg"

const ComparisonTable = ({ data, styleFull = false }) => {
  const { title, subtitle, tableRow } = data

  const ComparisonRow = ({ row = {} }) => {
    const { title: rowTitle, tableCol: columns } = row
    const isHeader = col => col.logo || col.name
    const getBgColor = color =>
      color === "sand" ? "bg-beige" : color === "mint" ? "bg-mint" : "bg-white"
    return (
      <>
        {rowTitle?.length > 1 && (
          <tr className={`openSans-SemiBold d-block d-lg-none mt-3 mb-1`}>
            <td className="rounded-0">{rowTitle}</td>
          </tr>
        )}
        <tr className={`flex align-text-center`}>
          <td className={`openSans-SemiBold d-lg-grid d-none `}>
            {rowTitle}
          </td>
          {columns &&
            columns.length &&
            columns.map((col, index) => (
              <td
                key={index}
                className={`flex-1 mx-1 rounded-0 ${
                  !isHeader(col) ? getBgColor(col.bgColor) : ""
                }`}
              >
                {!isHeader(col) && (
                  <div>
                    <Img
                      image={col.tick ? tick : cross}
                      className={`${style.image}`}
                    />
                    <div className={`${style.label} `}>{col.label}</div>
                  </div>
                )}
              </td>
            ))}
        </tr>
      </>
    )
  }

  return (
    <div
      className={`${styleFull ? "" : style.comparisonTable} text-center pt-3`}
    >
      {title && (
        <h2 className={`ming`}>
          <CustomMarkdown html={title} color={`copper`} />
        </h2>
      )}
      {subtitle && <CustomMarkdown html={subtitle} color={`copper`} />}
      {tableRow && tableRow.length && (
        <table className={`my-3`}>
          <thead>
            <tr className={`align-text-center ${style.header}`}>
              <th>
                <div className="flex justify-content-around align-items-center">
                  <div
                    className={`col-4 py-3 px-2 d-none d-lg-block mx-3 border-0`}
                  />
                  {tableRow[0].tableCol &&
                    tableRow[0].tableCol.map((col, index) => (
                      <React.Fragment key={index}>
                        {col.logo && (
                          <>
                            <div className={`col-4 col-lg-2 py-3 px-3`}>
                              <Img
                                image={col.logo}
                                className={`${style.logo}`}
                              />
                            </div>
                          </>
                        )}
                        {col.name && (
                          <div
                            className={`col-4 col-lg-2 py-3 px-2 text-center openSans-Bold `}
                          >
                            {col.name}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableRow.slice(1).map((row, i) => (
              <ComparisonRow key={i} row={row} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ComparisonTable
